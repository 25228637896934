<template>
  <div class="sgl-footer--wrapper">
    <div class="container">
      <div class="sgl-footer">
        <div class="sgl-footer--logo">
          <img src="@/img/logo.svg" alt="">
        </div>
        <nav class="sgl-footer--nav">
          <div class="footer-dropdown" @mouseover="toggleList" @mouseout="toggleList">
            <div :class="[{active:tournamentsListVisible}, 'button']"
                 @click.self="$router.push({ name: 'allTournaments'})">
              Все турниры
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6.5L8 10.5L4 6.5" stroke="#EAEAEA" stroke-linejoin="round"/>
              </svg>
            </div>
            <div ref="listContainer" class="list-container">
              <div class="list-wrapper" v-if="dataReady">
                <router-link :to="{name: 'sgl-events', params: {id: game.id}}" class="discipline-logo"
                             v-for="game in this.GET_TOURNAMENT_GROUPS" :key="game.id"
                             active-class=""
                             exact-active-class="active">
                  <img :src="GET_SPONSOR_GAMES.find(el => String(el.id) === String(game.gameInfo.id)).logo" alt="#">
                </router-link>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <router-link to="/videos">Видео</router-link>
            </li>
            <li>
              <router-link to="/FAQ">FAQ</router-link>
            </li>
          </ul>
        </nav>

        <footer-component-socials :social-links="footerInfo.links"/>

        <div class="sgl-footer--bot-group">

          <a v-if="this.footerInfo.file_user_agreement"
             :href="this.footerInfo.file_user_agreement">
            Пользовательское соглашение
          </a>

          <a v-if="this.footerInfo.file_privacy_policy"
             :href="this.footerInfo.file_privacy_policy"
             target="_blank">
            Политика конфиденциальности
          </a>
          <span>SGL eSports holding company® 2021</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FooterComponentSocials from "@/components/footer/footerComponent-socials";
import {API} from "@/Vuex/axios-config";
import {includeFilteringByType} from "@/otherJS/include-filtering";
import {SPONSOR_ID} from "@/config";

export default {
  name: "footerComponent",
  components: {FooterComponentSocials},
  data() {
    return {
      tournamentsListVisible: false,
      dataReady: false,
      footerInfo: {}
    }
  },
  methods: {
    ...mapActions([
      'GET_SPONSOR_DISCIPLINES',
      'SET_TOURNAMENT_GROUPS'
    ]),
    toggleList() {
      if (window.matchMedia("(min-width: 525px)").matches) {
        if (this.tournamentsListVisible) {
          this.$refs.listContainer.style.maxHeight = `0px`
          this.tournamentsListVisible = false
        } else {
          this.$refs.listContainer.style.maxHeight = `${this.$refs.listContainer.scrollHeight}px`
          this.tournamentsListVisible = true
        }
      }
    },
    async getData() {
      //TODO надо будет убрать отсюда, потому что в App.vue мы их уже получаем. Сделать прелоадер в шапке, пока они грузятся
      await this.GET_SPONSOR_DISCIPLINES()
      await this.SET_TOURNAMENT_GROUPS()
      this.dataReady = true
    },
    async getFooterInfo() {
      const response = await API({
        method: 'get',
        url: `/footer-infos?filter[sponsor_id]=${SPONSOR_ID}&include=socialLinks.logo,filePrivacyPolicy,fileUserAgreement`
      })
      if (response.data.data.length) {
        const footerInfo = {}

        const links = includeFilteringByType(response.data.included, 'social-links')
        const attachments = includeFilteringByType(response.data.included, 'attachments')

        for (let link of links) {
          for (let logo of attachments) {
            link.logo = attachments.find(el => String(el.id) === String(link.relationships.logo.data.id)).attributes.url
          }
        }

        footerInfo.file_privacy_policy = attachments.find(el => String(el.id) === String(response.data.data[0].relationships.filePrivacyPolicy.data.id)).attributes.url
        footerInfo.file_user_agreement = attachments.find(el => String(el.id) === String(response.data.data[0].relationships.fileUserAgreement.data.id)).attributes.url
        footerInfo.links = links

        this.footerInfo = footerInfo
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SPONSOR_GAMES',
      'GET_TOURNAMENT_GROUPS'
    ]),
  },
  mounted() {
    this.getData()
    this.getFooterInfo()
  }
}
</script>

<style scoped lang="scss">
</style>
